import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { ApiDataService } from 'src/@PePo/utils/api-data.service';
import { PePoService } from 'src/@PePo/utils/PePo.service';
import { API_URLS } from 'src/assets/constants/API_URLS';

@Component({
  selector: 'app-download-form',
  templateUrl: './download-form.component.html',
  styleUrls: ['./download-form.component.scss']
})
export class DownloadFormComponent implements OnInit {

  form: FormGroup = new FormGroup({});
  constructor(
    private _formBuilder: FormBuilder,
    private toaster: ToastrService,
    public pepo: PePoService,
    private _apiData: ApiDataService,
    private _translate: TranslateService,

  ) { }

  ngOnInit(): void {
    this.initForm();
  }

  initForm() {
    this.form = this._formBuilder.group({
      FullName: this._formBuilder.control('', [Validators.required, Validators.pattern(/^[\u0600-\u065F\u066A-\u06EF\u06FA-\u06FFa-zA-Z]/),
      ]),
      email: this._formBuilder.control('', [
        Validators.email,
        Validators.required,
      ]),
    });
  }

  getEmail(): FormControl {
    return this.form.get('email') as FormControl;
  }
  submitForm() {
    if (this.form.invalid) {
      this.toaster.error(this._translate.instant('Fields_required'));
    } else {
      let Payload;
      let formValue = this.form.value;
      console.log(formValue)
      Payload = {
        firstName: formValue.FullName,
        lastName: formValue.FullName,
        email: formValue.email,
        subject: "string",
        message: "string"
      }
      console.log(Payload)
        this._apiData.post(API_URLS.Contact_us.post,Payload,'',this.pepo.AuthHeaders)?.subscribe((res) => {
        console.log(res)
        this.toaster.success(this._translate.instant('Thanks'));
        this.form.reset();
      });
    }
  }

  // submitForm() {
  //   if (this.form.invalid) {
  //     this.toaster.error('please fill all fields');
  //   } else {
  //     // Sumbit form
  //     const Payload = this.form.value;
  //     console.log(Payload)
  //     // this._http.post(this.googleSheetUrl, Payload)?.subscribe((res) => {
  //       this._apiData.post(API_URLS.Contact_us.post,Payload,'',this._PePo.AuthHeaders)?.subscribe((res) => {
  //       console.log(res)
  //       this.toaster.success('Thanks for contacting');
  //       this.form.reset();
  //     });
  //   }
  // }
}
