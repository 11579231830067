import { Component, Input, OnInit } from '@angular/core';
import { PePoService } from 'src/@PePo/utils/PePo.service';

@Component({
  selector: 'app-section-head',
  templateUrl: './section-head.component.html',
  styleUrls: ['./section-head.component.scss']
})
export class SectionHeadComponent implements OnInit {
@Input() header_txt:string;
@Input() header_parag:string;
@Input()header_txt_Two :string;
  constructor(
    public PePo: PePoService,

  ) { }

  ngOnInit(): void {
  }

}
