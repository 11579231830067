<div class="downloadForm">
  <h3> {{'Download_PDF' | translate}} </h3>
  <form [formGroup]="form">
    <div class="form-group">
      <input class="form-control" name="full-name" value="" size="40" aria-required="true" aria-invalid="false"
        placeholder="{{'Full_Name' | translate}}" type="text" formControlName="FullName"
        [class.is-invalid]="form.get('FullName')?.invalid && form.get('FullName')?.touched">


      <div class="validation"
        *ngIf="(form.get('FullName')?.invalid && form.get('FullName')?.touched) && form.get('FullName')?.dirty">
        <small class="text-danger" *ngIf="form.get('FullName')?.errors?.required">{{'Footer.FnameReq' | translate}}</small>
        <small *ngIf="form.get('FullName')?.errors?.pattern">{{'Footer.RemoveSPace' | translate}}</small>

      </div>
    </div>
    <div class="form-group">
      <input class="form-control" name="email" value="" size="40" aria-required="true" aria-invalid="false"
        placeholder="{{'Email_Address' | translate}}" type="email" formControlName="email"
        [class.is-invalid]="getEmail().invalid && getEmail().touched">
      <div class="validation"
        *ngIf="(form.get('email')?.invalid && form.get('email')?.touched) && form.get('email')?.dirty">
        <small class="text-danger" *ngIf="form.get('email')?.errors?.required">{{'Footer.EmailReq' | translate}}</small>
        <small class="text-danger" *ngIf="form.get('email')?.errors?.email">{{'Footer.ValidEmail' | translate}}</small>
      </div>
    </div>

    <button class="form-control" type="submit" (click)="submitForm()">{{'Download' | translate}} <i class="fa"  [ngClass]="{'fa-long-arrow-left': pepo.currentLang =='ar','fa-long-arrow-right': pepo.currentLang =='en'}"  aria-hidden="true"></i></button><span></span>
  </form>
</div>
