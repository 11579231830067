import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-social-link-buttons',
  templateUrl: './social-link-buttons.component.html',
  styleUrls: ['./social-link-buttons.component.scss'],
})
export class SocialLinkButtonsComponent implements OnInit {
  @Input() url: any;
  @Input() description: any;
  constructor() {}

  ngOnInit(): void {}
}
