import { Injectable } from '@angular/core';
import { ApiDataService } from 'src/@PePo/utils/api-data.service';
import { PePoService } from 'src/@PePo/utils/PePo.service';
import { API_URLS } from 'src/assets/constants/API_URLS';

@Injectable({
  providedIn: 'root'
})
export class HomePageService {

  constructor(private _apiData: ApiDataService, private _PePo: PePoService) { }


  HeaderData() {
    return this._apiData.getData(API_URLS.HomeHeader.getWeb, this._PePo.AuthHeaders);
  }

  ServiesDate() {
    return this._apiData.getData(API_URLS.Services.getWeb, this._PePo.AuthHeaders)
  }

  SMVCSections(){
    return this._apiData.getData(API_URLS.Smvc.getWeb, this._PePo.AuthHeaders)
  }

  PartnersSections(){
    return this._apiData.getData(API_URLS.Partner.getWeb, this._PePo.AuthHeaders)
  }
  records(){
    return this._apiData.getData(API_URLS.Record.getWeb, this._PePo.AuthHeaders)
  }

  TeamsSections(){
    return this._apiData.getData(API_URLS.Teams.getWeb, this._PePo.AuthHeaders)
  }
  ContactUs(body){
    return this._apiData.post(API_URLS.Contact_us.post,body,'',this._PePo.AuthHeaders)
  }

  Footer(){
    return this._apiData.getData(API_URLS.Footer.getWeb,this._PePo.AuthHeaders)
  }
  getTypeService(){
    return this._apiData.getData(API_URLS.TypeService.getWeb,this._PePo.AuthHeaders)
  }
  getTypeServiceById(id){
    return this._apiData.getData(API_URLS.TypeService.getWebById.replace('{entityId}',id),this._PePo.AuthHeaders)
  }


}
