import { Injectable } from '@angular/core';
import { Meta, Title, MetaDefinition } from '@angular/platform-browser';
import { PageMetadata } from './PageMetadata.interface';
const defaultMetadata: PageMetadata = {
  title: 'App Title',
  description: 'App description',
  author: 'Diaa Ibrahim',
  keywords: ['Angular', 'meta tags', 'Angular Universal', 'symbios ksa'],
  type: 'website',
}
@Injectable({
  providedIn: 'root',
})
export class MetaDataService {
  constructor(
    private metaTagService: Meta,
    private titleService: Title,
  ) {}

  public updateMetadata(
    metadata: PageMetadata,
  ): void {

    // this.removeMetaTags()
    const pageMetadata: PageMetadata = { ...metadata };
    console.log(pageMetadata)
    const metatags: MetaDefinition[] =
      this.generateMetaDefinitions(pageMetadata);

    this.metaTagService.addTags([
      ...metatags,
      { name: 'viewport', content: 'width=device-width, initial-scale=1' },
      { 'http-equiv': 'Content-Type', content: 'text/html; charset=utf-8' },
    ]);

    this.titleService.setTitle(pageMetadata.title);
  }

  private generateMetaDefinitions(metadata: PageMetadata): MetaDefinition[] {
    return [
      { name: 'title', content: metadata.title },
      { property: 'og:title', content: metadata.title },

      { name: 'description', content: metadata.description },
      { property: 'og:description', content: metadata.description },

      { name: 'author', content: metadata.author },
      { property: 'og:author', content: metadata.author },

      { name: 'keywords', content: metadata.keywords.join(', ') },

      { property: 'og:type', content: metadata.type },
    ];
  }
}
