import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
// import { TranslateService } from '@ngx-translate/core';
import { Styles } from './interfaces/styles';
import { Lang } from './interfaces/lang.enum';
import { BehaviorSubject, Observable, of } from 'rxjs';
// import { locale } from '../../app/navigation/i18n/ar';
import { HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';

import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
// import { ar } from 'date-fns/locale';
// import { * as ar } from "src/app/navigation/i18n/ar" ;

@Injectable({
  providedIn: 'root',
})
export class PePoService {
  language: Observable<string>;
  // userTypeO: Observable<string>;
  userType$: BehaviorSubject<string> = new BehaviorSubject('');
  // login$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  FuseConfig$: BehaviorSubject<string> = new BehaviorSubject('');
  isAuthenticated = false;
  private token: string = '';
  constructor(
    private translate: TranslateService,
    private titleService: Title,
    private router:Router
  ) {
    //   console.log(localStorage.getItem('language'));
    this.language = of(localStorage.getItem('language') ?? 'en');
    // this.userTypeO = of(atob(sessionStorage.getItem(btoa('userType')) || '{}') ?? 'admin');
  }

  getAuthStatus(){
      // return this.login$.asObservable();
  }
  getToken(){
    return sessionStorage.getItem('token')
  }

  getIsAuth() {
    return this.isAuthenticated;
  }

  autoAuthUser() {
    const authInformation = this.getToken();
    const userTypeInformation = this.userType;


    if (authInformation == null && userTypeInformation == "ée") {
      console.log('auth',authInformation,userTypeInformation);
      this.router.navigate(['/pages/auth/login'])
      return;
    }
    this.token = authInformation || '{}';
    this.isAuthenticated = true;
    // this.login$.next(true);
    this.userType$.next(userTypeInformation);


  }

  logOut(){
    sessionStorage.clear();
    this.isAuthenticated = false;
    // this.login$.next(false);
    this.userType$.next('');
  }
  getUserTypeAsObservable()
  {
      return this.userType$.asObservable()
  }
  public get userType(): string {
    return atob(sessionStorage.getItem(btoa('userType')) || '{}');
  }

  public get loggedIn(): boolean {

    return sessionStorage.getItem('token') != null;

  }
  // Languages
  get currentLang() {
    return localStorage.getItem('language') ?? 'en';
  }
  get lang(): Observable<string> {
    return this.language;
  }


  get otherLang() {
    switch (localStorage.getItem('language')) {
      case 'en':
        return 'ar';
      case 'ar':
        return 'en';
      case 'tr':
        return 'tr';
      default:
        return 'en';
    }

  }

  switchLanguage(language: string, key?: string) {
    if (language in Lang) {
      //   if (this.currentLang == language) {
      this.translate.use(language);
      this.language = of(language);
      localStorage.setItem("language", language ?? 'en');
      this.setStyle();
    } else {
      this.translate.use('en');
      this.setStyle()
    }
  }
  inverseLang(keyLang:any) {
    return keyLang === 'en' ? 'ar' : 'en';
  }
  // Title
  setTitle(key: string) {
    return this.translate.get(key).subscribe((data) => {
      this.titleService.setTitle(data);
    });
  }

  // // Style
  setStyle() {
    const keyLang = localStorage.getItem('language') || this.currentLang || this.translate.getDefaultLang();
    const styleFiles = Styles[keyLang];
    const htmlElement = document.getElementsByTagName('html')[0];
    const headElements = document.getElementsByTagName('head')[0];

    htmlElement.setAttribute('lang', keyLang);
    if (keyLang === 'ar') {
      htmlElement.setAttribute('dir', 'rtl');
    } else {
      htmlElement.setAttribute('dir', 'ltr');
    }

  }

  public get AuthHeaders() : HttpHeaders {
    return  new HttpHeaders({
      authorization: 'Bearer ' + environment.clientId
    })
  }
  public get LoginHeaders() : HttpHeaders {
    return  new HttpHeaders({
      authorization: 'Bearer ' + 'iYQjx20okVH7gu6RI/16ksxlOLxHML+CrqMTGd2l2Keevl26rAKnP9eTE/7LjLm/'
    })
  }

  public get FilesAuthHeaders() : HttpHeaders {
    return  new HttpHeaders({
      Authorization: 'Bearer ' + environment.clientId
    })
  }

  isRTL(): boolean {
    let rtl = false;
    this.lang.subscribe(lang => {
      lang == 'ar' ? rtl = true : rtl = false;
    });
    return rtl;
  }

  public FuseConFiguration(config:any) {
      return config;
  }
  getFuseConfig()
  {
      return this.FuseConfig$.asObservable()
  }


}
