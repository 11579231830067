import { TranslateService } from '@ngx-translate/core';
import { Component, DoCheck, Input, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'app-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss'],
})
export class BreadcrumbsComponent implements OnInit , DoCheck {
  url: string = '';
  newString: string = '';
  @Input() Main_Route:string;
  @Input() PageName:string;
  @Input() background:string;
  constructor(private router: Router , public translate : TranslateService) {



    this.router.events?.subscribe((res) => {
      if (res instanceof NavigationEnd) {
        this.url = res?.url;
        if (
          this.url.includes('-', 0) ||
          this.url.includes('/', 0) ||
          this.url.includes('?id=0', 0) ||
          this.url.includes('?id=1', 0) ||
          this.url.includes('?id=2', 0) ||
          this.url.includes('?id=3', 0) ||
          this.url.includes('?id=4', 0) ||
          this.url.includes('?id=5', 0)
        ) {
          this.newString = this.url
            .replace('/', '')
            .replace('-', ' ')
            .replace('?id=0', '')
            .replace('?id=1', '')
            .replace('?id=2', '')
            .replace('?id=3', '')
            .replace('?id=4', '')
            .replace('?id=5', '');
        } else {
          this.newString = this.url;
        }
      }
    });
  }

  ngOnInit(): void {
    console.log(`background`,this.background)
  }
  ngDoCheck(): void {
    console.log(`PageName`,this.PageName)
      if(this.background == undefined){
        this.background = '../../../assets/images/about_us/aboutPage.jpg'
      }else {
        this.background = this.background
      }
  }
}
