import { Config } from 'protractor';
import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient, HttpParams } from '@angular/common/http';
import { catchError, tap } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { messagesStatus } from './messages.enum';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root',
})
export class ApiDataService {
  headers: HttpHeaders = new HttpHeaders();

  config?: Config;

  data: any;
  error: any;
  httpOptions?: { headers: HttpHeaders; observe: 'response' };
  public online: boolean = navigator.onLine;

  constructor(
    private http: HttpClient,
    private translate: TranslateService,
    private router: Router,
    private _snackBar: MatSnackBar
  ) {}

  post(
    url: string,
    body: any[] | any,
    toastMessage?: any,
    headers?: HttpHeaders,
    contentType?: string
  ) {
    if (this.online) {
      this.httpOptions = this.getHeadders(headers);
      // this.refreshToken();

      if (url && body) {
        return this.http.post<Config>(url, body, this.httpOptions).pipe(
          tap(
            // Log the result or error
            (data) => {
              if (toastMessage == false) {
                return data;
              }
              if (toastMessage && toastMessage !== false) {
                this.showToast(this.translate.instant(toastMessage));
                return data;
              } else {
                this.showToast(
                  this.translate.instant(messagesStatus.success.toString())
                );
                return data;
              }
            },
            (error) => this.resolveNotAuthrized(error)
          )
        );
      }
    } else {
      // this.showToast('You Are Offilne, Please Check your Internet Connection');
      this.router.navigate(['/500/errors/error-500']);
    }
  }
  // refreshToken() {
  //     let token = this.httpOptions.headers.get('Authorization');
  //     if (token){
  //         token = token.substr('Bearer '.length)
  //         if (token.length> environment.clientId.length){
  //             const tokenSplitted = token.split('.')[1];
  //             const tokenDecoded = JSON.parse(atob(tokenSplitted))

  //             if ((tokenDecoded['exp'] *1000 )<= Date.now()){
  //                 console.log(tokenDecoded);

  //                 new Promise(async (resolve,reject)=>{
  //                     await this.http.put(API_URLS.auth.putrefershToken,{
  //                         oldtoken : token,
  //                         email : tokenDecoded['email']
  //                     },{headers: new HttpHeaders(
  //                         {'Authorization': 'Bearer ' + environment.clientId }
  //                     )}).toPromise().then((res: any)=>{
  //                         sessionStorage.setItem("token", res.token);
  //                         resolve(res.token)
  //                     })
  //                 })

  //             }

  //         }
  //     }
  // }

  login(url: string, credintials: any) {
    if (this.online) {
      //Basic
      this.httpOptions = {
        headers: new HttpHeaders({
          Authorization: 'Bearer ' + environment.clientId,
          // 'content-type': 'application/x-www-form-urlencoded',
        }),
        observe: 'response',
      };
      if (url && credintials) {
        return this.http
          .post<Config>(url, credintials, this.httpOptions)
          .pipe(catchError((err) => this.resolveNotAuthrized(err)));
      } else {
        // this.showToast('You Are Offilne, Please Check your Internet Connection');
        this.router.navigate(['/500/errors/error-500']);
      }
    }
  }
  register(url: string, credintials: any) {
    if (this.online) {
      //Basic
      this.httpOptions = {
        headers: new HttpHeaders({
          Authorization: 'Bearer ' + environment.clientId,
        }),
        observe: 'response',
      };
      if (url && credintials) {
        return this.http
          .post<Config>(url, credintials, this.httpOptions)
          .pipe(catchError((err) => this.resolveNotAuthrized(err)));
      } else {
        this.showToast(
          'You Are Offilne, Please Check your Internet Connection'
        );
      }
    }
  }

  update(url: string, body: any, toastMessage?: any, headers?: HttpHeaders) {
    if (this.online) {
      this.httpOptions = this.getHeadders(headers);
      // this.refreshToken();

      if (url && body) {
        return this.http.put(url, body, this.httpOptions).pipe(
          tap(
            // Log the result or error
            (data) => {
              if (toastMessage == false) {
                return data;
              }
              if (toastMessage && toastMessage !== false) {
                this.showToast(this.translate.instant(toastMessage));
                return data;
              } else {
                this.showToast(
                  this.translate.instant(messagesStatus.update.toString())
                );
                return data;
              }
            },
            (error) => this.resolveNotAuthrized(error)
          )
        );
      }
    } else {
      this.showToast('You Are Offilne, Please Check your Internet Connection');
    }
  }

  delete(url: string, body: any, headers?: HttpHeaders, toastMessage?: any) {
    this.httpOptions = this.getHeadders(headers);
    // this.refreshToken();

    if (this.online) {
      if (url) {
        return this.http
          .request('delete', url, {
            body: body,
            headers: this.httpOptions.headers,
            observe: this.httpOptions.observe,
          })
          .pipe(
            tap(
              // Log the result or error
              (data) => {
                if (toastMessage == false) {
                  return data;
                }
                if (toastMessage && toastMessage !== false) {
                  this.showToast(this.translate.instant(toastMessage));
                  return data;
                } else {
                  this.showToast(
                    this.translate.instant(messagesStatus.delete.toString())
                  );
                  return data;
                }
              },
              (error) => this.resolveNotAuthrized(error)
            )
          );
      }
    } else {
      this.showToast('You Are Offilne, Please Check your Internet Connection');
    }
  }
  getData(url: string, headers?: HttpHeaders, contentType?: string) {
    if (this.online) {
      this.httpOptions = this.getHeadders(headers);
      // this.refreshToken();

      if (url) {
        return this.http
          .get<Config>(url, this.httpOptions)
          .pipe(catchError((err) => this.resolveNotAuthrized(err)));
      }
    } else {
      this.showToast('You Are Offilne, Please Check your Internet Connection');
    }
  }
  resolveNotAuthrized(error: any) {
    console.log('error', error.error.message);
    if (error.status == 401) {
      this.showErrToast(
        this.translate.instant(error.error.message),
        error.error.statusCode
      );
      this.router.navigate(['/pages/auth/login']);
    } else {
      this.showErrToast(
        this.translate.instant(error.error.message),
        error.error.statusCode
      );
      return error;
    }
  }
  showToast(message: any) {
    this._snackBar.open(this.translate.instant(message), '', {
      duration: 3000,
      horizontalPosition: 'center',
      verticalPosition: 'bottom',
    });
  }
  showErrToast(message: any, status: any) {
    this._snackBar.open(this.translate.instant(message), status, {
      duration: 3000,
      announcementMessage: this.translate.instant(message),
      horizontalPosition: 'center',
      verticalPosition: 'bottom',
    });
  }

  private getHeadders(incomingHeaders: HttpHeaders): {
    headers: HttpHeaders;
    observe: 'response';
  } {
    // console.log(sessionStorage.getItem('token'));

    return {
      headers: incomingHeaders
        ? incomingHeaders
        : new HttpHeaders({
            Authorization: 'Bearer ' + sessionStorage.getItem('token'),
          }),
      observe: 'response',
    };
  }
}
