<div id="loader-wrapper" [ngClass]="{'hidden': !showOverlay}">
  <div id="loader"></div>
  <div class="loader-section section-left"></div>
  <div class="loader-section section-right"></div>
</div>


<!-- STICKY LEFT BUTTON -->
<div class="nw_stickyleft_button">
  <a href="#"><i class="icon-basket-loaded icons"></i></a>
  <a href="#"><i class="icon-book-open icons"></i></a>
  <a href="#"><i class="icon-support icons"></i></a>
</div>
<a id="scroll" style="display: none;"><i class="fa fa-long-arrow-up"></i></a>
<!-- HEADER -->

<!-- home page navigation bar  -->


<!-- all project navigation bar except home page  -->
<ng-container *ngIf="routeLink !== '/' && innerWidth > 426 &&  routeLink != '/Engagement/video_preview'">
  <!-- HEADER -->
  <header class="nw_header_style" id="header_syle2">
    <!-- TOP HEADER -->
    <div class="nw_top_header">
      <div class="container">
        <div class="row">
          <div class="col-sm-7">
            <!-- TOP HEADER INFO -->
            <div class="nw_header_info_group nw_text_center">
              <div class="pull-left nw_header_label">
                <a href="https://gmail.com"><i class="fa fa-envelope" aria-hidden="true"></i>symbios-ksa.com</a>
              </div>
              <div class="nw_header_label pull-left">
                <a href="http://skype.com"><i>Call us:</i>+966 11 827 4289</a>
              </div>
            </div>
          </div>
          <div class="col-sm-5">
            <!-- TOP HEADER INFO -->
            <div class="nw_header_info_group nw_text_center">
              <div class="pull-right Top_social">
                <i style="color: white;">Follow:</i>
                <ul class="nw_list_inline">
                  <li><a href="https://www.facebook.com/symbiosconsulting2005/" target="_blank"><i
                        class="fa fa-facebook"></i></a></li>
                  <li><a href="https://twitter.com/symbios_group" target="_blank"><i class="fa fa-twitter"></i></a></li>
                  <!-- <li><a href="http://plus.google.com"><i class="fa fa-google-plus"></i></a></li> -->
                  <li><a href="https://www.youtube.com/channel/UCx7HKNnSIlEEE5JKI4JsA4A" target="_blank"><i
                        class="fa fa-youtube"></i></a></li>
                  <li><a href="https://www.linkedin.com/company/symbios-consulting" target="_blank"><i
                        class="fa fa-linkedin"></i></a></li>
                  <!-- <li> <button class="english" (click)="setLanguage('en')"></button></li> -->
                  <!-- <li> <button class="arabic" (click)="setLanguage('ar')"></button></li> -->
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- NAVBAR -->
    <div class="navbar navbar-default nw_navbar_custom" id="navigation">
      <div class="container">
        <!-- <div class="nw_navbar_custom_cont" style="padding:0"> -->
        <div class="row nw_navbar_custom_row">
          <!-- LOGO -->
          <div class="nw_navbar_header navbar-header col-sm-4">
            <!-- NAVIGATION BURGER MENU -->
            <button type="button" class="nw_navbar_button navbar-toggle collapsed" data-toggle="collapse"
              data-target="#navbar" aria-expanded="false" aria-controls="navbar">
              <span class="sr-only"></span>
              <span class="icon-bar"></span>
              <span class="icon-bar"></span>
              <span class="icon-bar"></span>
            </button>

            <a class="nw_logo" href="index.html">
              <img src="assets/images/Arabic_blue.png" style="margin-inline: 5px;" alt="Ksa">
              <!-- <img src="../assets/images/Symbios-English-Logo-4.png" style="margin-inline: 5px;"alt="egypt"> -->
              <!-- <img src="assets/images/vision2030.png" class="suadi_flag" alt=""> -->
            </a>
          </div>
          <!-- NAV MENU -->
          <div id="navbar" class="nw_navbar_menu navbar-collapse collapse col-sm-8">
            <ul class="nw_menu nav navbar-nav navbar-right">
              <li class="nw_submenu">
                <a href="index.html" aria-haspopup="true" aria-expanded="false">{{'Home' | translate}}</a>
              </li>
              <li class="nw_submenu">
                <a class="arrow_after clickAnchor" (click)="$event.stopPropagation()" aria-haspopup="true"
                  aria-expanded="false" [ngClass]="{'active': company_active}">{{'Company' | translate}} </a>
                <ul class="nw_submenu_items">

                  <li routerLinkActive="active_route" [routerLinkActiveOptions]="{ exact: true }"><a
                      [routerLink]="'/about-us'">{{'About_Us' | translate}}</a></li>
                  <li routerLinkActive="active_route" [routerLinkActiveOptions]="{ exact: true }"><a
                      routerLink="/why-us">{{'Why_Symbios' | translate}}</a></li>
                  <li routerLinkActive="active_route" [routerLinkActiveOptions]="{ exact: true }"><a
                      routerLink="/approach">{{'Approach' | translate}}</a></li>
                  <li routerLinkActive="active_route" [routerLinkActiveOptions]="{ exact: true }"><a
                      routerLink="/achievements">{{'Achievements' | translate}}</a></li>
                  <li routerLinkActive="active_route" [routerLinkActiveOptions]="{ exact: true }"><a
                      routerLink="/partners">{{'Partners' | translate}}</a></li>
                  <li routerLinkActive="active_route" [routerLinkActiveOptions]="{ exact: true }"><a
                      routerLink="/contact-us">{{'Contact_Us' | translate}}</a></li>
                  <li routerLinkActive="active_route" [routerLinkActiveOptions]="{ exact: true }"><a
                      [routerLink]="'/Events'">{{'Events' | translate}}</a></li>

                  <!-- <li><a  routerLinkActive="active_route" [routerLinkActiveOptions]="{ exact: true }" routerLink="/team">Team</a></li> -->
                  <!-- <li><a routerLinkActive="active_route" [routerLinkActiveOptions]="{ exact: true }"
                      routerLink="/vison2030">Vision 2030</a></li> -->
                  <!-- <li><a routerLinkActive="active_route" [routerLinkActiveOptions]="{ exact: true }"
                        routerLink="/Blog">Blog</a></li>
                    <li><a routerLinkActive="active_route" [routerLinkActiveOptions]="{ exact: true }"
                        routerLink="/newsRooms">Newsroom</a></li>
                    <li><a routerLinkActive="active_route" [routerLinkActiveOptions]="{ exact: true }"
                        routerLink="/success-stories">Success Stories</a></li> -->
                </ul>
              </li>

              <li class="nw_submenu">
                <a class="arrow_after" (click)="$event.stopPropagation()" aria-haspopup="true" aria-expanded="false"
                  routerLinkActive="active_route" [ngClass]="{'active': service_active}">{{'Services' | translate}}</a>
                <ul class="nw_submenu_items">
                  <!-- <li><a routerLinkActive="active_route" [routerLinkActiveOptions]="{ exact: true }"
                      routerLink="/business-excellence">Business Excellence</a></li>
                  <li><a routerLinkActive="active_route" [routerLinkActiveOptions]="{ exact: true }"
                      routerLink="/cost-optimization">Cost Optimization & Operational Excellence</a></li>
                  <li><a routerLinkActive="active_route" [routerLinkActiveOptions]="{ exact: true }"
                      routerLink="/supply-chain-optimization">Supply Chain Optimization</a></li>
                  <li><a routerLinkActive="active_route" [routerLinkActiveOptions]="{ exact: true }"
                      routerLink="/digital-transformation">Digital Transformation</a></li>
                  <li><a routerLinkActive="active_route" [routerLinkActiveOptions]="{ exact: true }"
                      routerLink="/business-reEngineering">Business Re-Engineering</a></li>
                  <li><a routerLinkActive="active_route" [routerLinkActiveOptions]="{ exact: true }"
                      routerLink="/human-resource">Human Resources</a></li>
                  <li><a routerLinkActive="active_route" [routerLinkActiveOptions]="{ exact: true }"
                      routerLink="/training-workshops">Training & Workshops</a></li> -->

                  <li><a routerLinkActive="active_route" [routerLinkActiveOptions]="{ exact: true }"
                      routerLink="/business-excellence">{{'Business_Excellence' | translate}}</a></li>
                  <li><a routerLinkActive="active_route" [routerLinkActiveOptions]="{ exact: true }"
                      routerLink="/cost-optimization">{{'Cost_Optimization' | translate}} & <br>
                      {{'Operational_Excellence' | translate}}</a></li>
                  <li><a routerLinkActive="active_route" [routerLinkActiveOptions]="{ exact: true }"
                      routerLink="/supply-chain-optimization">{{'Supply_Chain_Optimization' | translate}}</a></li>
                  <li><a routerLinkActive="active_route" [routerLinkActiveOptions]="{ exact: true }"
                      routerLink="/digital-transformation">{{'Digital_Transformation' | translate}}</a></li>
                  <li><a routerLinkActive="active_route" [routerLinkActiveOptions]="{ exact: true }"
                      routerLink="/business-reEngineering">{{'Business_Re_Engineering' | translate}}</a></li>
                  <li><a routerLinkActive="active_route" [routerLinkActiveOptions]="{ exact: true }"
                      routerLink="/human-resource">{{'Human_Resources' | translate}}</a></li>
                  <li><a routerLinkActive="active_route" [routerLinkActiveOptions]="{ exact: true }"
                      routerLink="/training-workshops">{{'Training_Workshops' | translate}}</a></li>
                </ul>
              </li>


              <!-- <li class="nw_submenu">
                      <a class="active" href="index.html"  aria-haspopup="true" aria-expanded="false">Sample Projects</a>
                    </li> -->
              <li class="nw_submenu" style="padding-right: 8px;">
                <a class="arrow_after" (click)="$event.stopPropagation()" aria-haspopup="true" aria-expanded="false"
                  [ngClass]="{'active': our_stories_active}">{{'Our_Stories' |translate}}</a>
                <ul class="nw_submenu_items">
                  <!-- <li><a routerLinkActive="active_route" [routerLinkActiveOptions]="{ exact: true }"
                      routerLink="/industries">Industries</a></li>
                  <li><a routerLinkActive="active_route" [routerLinkActiveOptions]="{ exact: true }"
                      routerLink="/return-on-investment"> Return on Investment</a></li>
                  <li><a routerLinkActive="active_route" [routerLinkActiveOptions]="{ exact: true }"
                      routerLink="/Clients">Our Clients</a></li>
                  <li><a routerLinkActive="active_route" [routerLinkActiveOptions]="{ exact: true }"
                      routerLink="/gallery">Gallery</a></li> -->
                  <!-- <li><a routerLinkActive="active_route" [routerLinkActiveOptions]="{ exact: true }"
                      routerLink="/case-studies">Case Studies</a></li>
                  <li><a routerLinkActive="active_route" [routerLinkActiveOptions]="{ exact: true }"
                      routerLink="/insight">Report & Insight</a></li> -->

                  <li><a routerLinkActive="active_route" [routerLinkActiveOptions]="{ exact: true }"
                      routerLink="/industries">{{'Industries' | translate}}</a></li>
                  <li><a routerLinkActive="active_route" [routerLinkActiveOptions]="{ exact: true }"
                      routerLink="/return-on-investment"> {{'Return_on_Investment' | translate}}</a></li>
                  <li><a routerLinkActive="active_route" [routerLinkActiveOptions]="{ exact: true }"
                      routerLink="/Clients">{{'Our_Clients' | translate}}</a></li>
                  <li><a routerLinkActive="active_route" [ngClass]="{'active_route': routeLink.includes('/photo-datails')}" [routerLinkActiveOptions]="{ exact: true }"
                      routerLink="/gallery">{{'Gallery' | translate}}</a></li>
                  <li><a routerLinkActive="active_route" [routerLinkActiveOptions]="{ exact: true }"
                      routerLink="/Engagement">{{'Engagement' | translate}}</a></li>
                  <li><a routerLinkActive="active_route" [ngClass]="{'active_route': routeLink.includes('/case-single')}" [routerLinkActiveOptions]="{ exact: true }"
                      routerLink="/case-studies">{{'Case_Studies' | translate}}</a></li>
                  <li><a routerLinkActive="active_route" [routerLinkActiveOptions]="{ exact: true }"
                      routerLink="/insight">{{'Report_Insight' | translate}}</a></li>
                  <li><a routerLinkActive="active_route" [ngClass]="{'active_route': routeLink.includes('/Blog-single')}" [routerLinkActiveOptions]="{ exact: true }"
                      routerLink="/Blog">{{'Blogs' | translate}}</a></li>
                  <!-- <li><a routerLinkActive="active_route" [routerLinkActiveOptions]="{ exact: true }"
                        routerLink="/Books">Books</a></li> -->
                  <!-- <li><a routerLinkActive="active_route" [routerLinkActiveOptions]="{ exact: true }"
                        routerLink="/study-tours">Study Tours</a></li> -->
                  <!-- <li><a routerLinkActive="active_route" [routerLinkActiveOptions]="{ exact: true }"
                        routerLink="/Alumni">Almuni</a></li> -->
                </ul>
              </li>
              <!-- <li class="nw_submenu">
                  <a class="arrow_after" (click)="$event.stopPropagation()" aria-haspopup="true"
                    aria-expanded="false">Learn</a>
                  <ul class="nw_submenu_items">
                    <li><a routerLinkActive="active_route" [routerLinkActiveOptions]="{ exact: true }"
                        routerLink="/insight">Insights</a></li>
                    <li><a routerLinkActive="active_route" [routerLinkActiveOptions]="{ exact: true }"
                        routerLink="/videos">Videos</a></li>
                    <li><a routerLinkActive="active_route" [routerLinkActiveOptions]="{ exact: true }"
                        routerLink="/gallery">Gallery</a></li>
                    <li><a routerLinkActive="active_route" [routerLinkActiveOptions]="{ exact: true }"
                        routerLink="/webinars">Webinars</a></li>
                    <li><a routerLinkActive="active_route" [routerLinkActiveOptions]="{ exact: true }"
                        routerLink="/Books">Books</a></li>
                    <li><a routerLinkActive="active_route" [routerLinkActiveOptions]="{ exact: true }"
                        routerLink="/Courses-booking">Courses booking</a></li>
                  </ul>
                </li> -->
              <li class="nw_submenu ">
                <a class="nw_purchase_now" href="http://www.symbioslms.com" aria-haspopup="true"
                  target="_blank">{{'LMS'|translate}}</a>
              </li>

              <!-- <li class="nw_submenu">
                <a class="arrow_after" (click)="$event.stopPropagation()" aria-haspopup="true"
                  aria-expanded="false">{{'Languages' |translate}}</a>
                <ul class="nw_submenu_items language_menu">
                  <li> <a [ngClass]="{'active_route': PePo.currentLang == 'en'}" (click)="setLanguage('en')">{{'English' | translate}}</a></li>
                  <li> <a (click)="setLanguage('ar')" [ngClass]="{'active_route': PePo.currentLang == 'ar'}">{{'Arabic' | translate}}</a></li>
                </ul>
              </li> -->
            </ul>
          </div>
        </div>
        <!-- </div> -->
      </div>
    </div>
  </header>
</ng-container>



<!-- mobile navigation bar  -->
<ng-container *ngIf="routeLink !== '/' &&  innerWidth <= 426">
  <header class="main_header">
    <div class="nw_top_header">
      <div class="container">
        <div class="row">
          <div class="col-sm-7">
            <div class="nw_header_info_group nw_text_center">
              <div class="pull-left">
                <a href="https://gmail.com"><i class="fa fa-envelope" aria-hidden="true"></i>symbios-ksa.com</a>
              </div>
              <div class="nw_header_label pull-left">
                <a href="http://skype.com"><span>Call us:</span>+966 11 827 4289</a>
              </div>
            </div>
          </div>
          <div class="col-sm-12">
            <div class="nw_header_info_group nw_text_center">
              <div class="pull-right Top_social">
                <span>Follow:</span>
                <ul class="nw_list_inline">
                  <li><a href="https://www.facebook.com/symbiosconsulting2005/" target="_blank"><i
                        class="fa fa-facebook"></i></a></li>
                  <li><a href="https://twitter.com/symbios_group" target="_blank"><i class="fa fa-twitter"></i></a></li>
                  <li><a href="https://www.youtube.com/channel/UCx7HKNnSIlEEE5JKI4JsA4A" target="_blank"><i
                        class="fa fa-youtube"></i></a></li>
                  <li><a href="https://www.linkedin.com/company/symbios-consulting" target="_blank"><i
                        class="fa fa-linkedin"></i></a></li>
                  <!-- <li> <button class="english" (click)="setLanguage('en')"></button></li> -->
                  <!-- <li> <button class="arabic" (click)="setLanguage('ar')"></button></li> -->
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>


    <nav class="navbar navbar-default">
      <div class="container">
        <div class="navbar-header" style="width: 100%;;margin: 0;">
          <button type="button" id="toggleBtn" class="navbar-toggle collapsed">
            <span class="sr-only">Toggle navigation</span>
            <span class="icon-bar"></span>
            <span class="icon-bar"></span>
            <span class="icon-bar"></span>
          </button>
          <a class="nw_logo" href="index.html"><img src="assets/images/Arabic_blue.png" style="margin-top: 5px;"
              alt="Ksa">
            </a>
        </div>
        <div id="navbar" class="navbar-collapse collapsed_navbar collapse" style="margin: 0;">
          <ul class="nav navbar-nav" [ngClass]="{'padding-top': PePo.currentLang == 'ar'}" >
            <li class="active"><a href="index.html">{{'Home' | translate}}</a></li>
            <li class="dropdown">
              <a class="dropdown-toggle" id="company_drb" aria-expanded="false">{{'Company' | translate}}  <span class="caret"></span></a>
              <ul class="dropdown-menu" id="compantList">
                <!-- <div class="company-List mobile-nav"> -->
                <!-- <span style="padding-bottom: 10px;">Company</span> -->
                <li routerLinkActive="active_route" [routerLinkActiveOptions]="{ exact: true }"><a
                  [routerLink]="'/about-us'">{{'About_Us' | translate}}</a></li>
              <li routerLinkActive="active_route" [routerLinkActiveOptions]="{ exact: true }"><a
                  routerLink="/why-us">{{'Why_Symbios' | translate}}</a></li>
              <li routerLinkActive="active_route" [routerLinkActiveOptions]="{ exact: true }"><a
                  routerLink="/approach">{{'Approach' | translate}}</a></li>
              <li routerLinkActive="active_route" [routerLinkActiveOptions]="{ exact: true }"><a
                  routerLink="/achievements">{{'Achievements' | translate}}</a></li>
              <li routerLinkActive="active_route" [routerLinkActiveOptions]="{ exact: true }"><a
                  routerLink="/partners">{{'Partners' | translate}}</a></li>
              <li routerLinkActive="active_route" [routerLinkActiveOptions]="{ exact: true }"><a
                  routerLink="/contact-us">{{'Contact_Us' | translate}}</a></li>
              <li routerLinkActive="active_route" [routerLinkActiveOptions]="{ exact: true }"><a
                  [routerLink]="'/Events'">{{'Events' | translate}}</a></li>
                <!-- </div> -->
                <!-- <div class="blog-list mobile-nav">
                  <span style="padding-bottom: 10px;">Media</span>
                  <li><a routerLinkActive="active_route" [routerLinkActiveOptions]="{ exact: true }"
                      routerLink="/Blog">Blog</a></li>
                  <li><a routerLinkActive="active_route" [routerLinkActiveOptions]="{ exact: true }"
                      routerLink="/newsRooms">Newsroom</a></li>
                  <li><a routerLinkActive="active_route" [routerLinkActiveOptions]="{ exact: true }"
                      routerLink="/success-stories">Success Stories</a></li>
                </div> -->
              </ul>
            </li>

            <li class="dropdown">
              <a class="dropdown-toggle" id="service_drb" aria-expanded="false">{{'Services' | translate}} <span class="caret"></span></a>
              <ul class="dropdown-menu" id="service_DDL">
                <li><a routerLinkActive="active_route" [routerLinkActiveOptions]="{ exact: true }"
                  routerLink="/business-excellence">{{'Business_Excellence' | translate}}</a></li>
              <li><a routerLinkActive="active_route" [routerLinkActiveOptions]="{ exact: true }"
                  routerLink="/cost-optimization">{{'Cost_Optimization' | translate}} & <br>
                  {{'Operational_Excellence' | translate}}</a></li>
              <li><a routerLinkActive="active_route" [routerLinkActiveOptions]="{ exact: true }"
                  routerLink="/supply-chain-optimization">{{'Supply_Chain_Optimization' | translate}}</a></li>
              <li><a routerLinkActive="active_route" [routerLinkActiveOptions]="{ exact: true }"
                  routerLink="/digital-transformation">{{'Digital_Transformation' | translate}}</a></li>
              <li><a routerLinkActive="active_route" [routerLinkActiveOptions]="{ exact: true }"
                  routerLink="/business-reEngineering">{{'Business_Re_Engineering' | translate}}</a></li>
              <li><a routerLinkActive="active_route" [routerLinkActiveOptions]="{ exact: true }"
                  routerLink="/human-resource">{{'Human_Resources' | translate}}</a></li>
              <li><a routerLinkActive="active_route" [routerLinkActiveOptions]="{ exact: true }"
                  routerLink="/training-workshops">{{'Training_Workshops' | translate}}</a></li>
              </ul>
            </li>
            <li class="dropdown">
              <a class="dropdown-toggle " id="stories_drb" aria-expanded="false">{{'Our_Stories' |translate}}<span
                  class="caret"></span></a>
              <ul class="dropdown-menu" id="srories_DDL">
                <li><a routerLinkActive="active_route" [routerLinkActiveOptions]="{ exact: true }"
                  routerLink="/industries">{{'Industries' | translate}}</a></li>
              <li><a routerLinkActive="active_route" [routerLinkActiveOptions]="{ exact: true }"
                  routerLink="/return-on-investment"> {{'Return_on_Investment' | translate}}</a></li>
              <li><a routerLinkActive="active_route" [routerLinkActiveOptions]="{ exact: true }"
                  routerLink="/Clients">{{'Our_Clients' | translate}}</a></li>
              <li><a routerLinkActive="active_route" [routerLinkActiveOptions]="{ exact: true }"
                  routerLink="/gallery">{{'Gallery' | translate}}</a></li>
              <li><a routerLinkActive="active_route" [routerLinkActiveOptions]="{ exact: true }"
                  routerLink="/Engagement">{{'Engagement' | translate}}</a></li>
              <li><a routerLinkActive="active_route" [routerLinkActiveOptions]="{ exact: true }"
                  routerLink="/case-studies">{{'Case_Studies' | translate}}</a></li>
              <li><a routerLinkActive="active_route" [routerLinkActiveOptions]="{ exact: true }"
                  routerLink="/insight">{{'Report_Insight' | translate}}</a></li>
              <li><a routerLinkActive="active_route" [routerLinkActiveOptions]="{ exact: true }"
                  routerLink="/Blog">{{'Blogs' | translate}}</a></li>
              </ul>
            </li>

            <!-- <li class="dropdown">
              <a href="#" class="dropdown-toggle " data-toggle="dropdown" role="button" aria-haspopup="true"
                aria-expanded="false">Learn <span class="caret"></span></a>
              <ul class="dropdown-menu">
                <li><a routerLinkActive="active_route" [routerLinkActiveOptions]="{ exact: true }"
                    routerLink="/insight">Insights</a></li>
                <li><a routerLinkActive="active_route" [routerLinkActiveOptions]="{ exact: true }"
                    routerLink="/videos">Videos</a></li>
                <li><a routerLinkActive="active_route" [routerLinkActiveOptions]="{ exact: true }"
                    routerLink="/gallery">Gallery</a></li>
                <li><a routerLinkActive="active_route" [routerLinkActiveOptions]="{ exact: true }"
                    routerLink="/webinars">Webinars</a></li>
                <li><a routerLinkActive="active_route" [routerLinkActiveOptions]="{ exact: true }"
                    routerLink="/Books">Books</a></li>
                <li><a routerLinkActive="active_route" [routerLinkActiveOptions]="{ exact: true }"
                    routerLink="/Courses-booking">Courses booking</a></li>
              </ul>
            </li> -->
            <li><a href="http://www.symbioslms.com" aria-haspopup="true" target="_blank">{{'LMS'|translate}}</a></li>
            <!-- <li class="dropdown">
              <a href="#" class="dropdown-toggle " data-toggle="dropdown" role="button" aria-haspopup="true"
                aria-expanded="false">{{'Languages' | translate}} <span class="caret"></span></a>
              <ul class="dropdown-menu">
                <li> <a [ngClass]="{'active_route': PePo.currentLang == 'en'}" (click)="setLanguage('en')">{{'English' | translate}}</a></li>
                <li> <a (click)="setLanguage('ar')" [ngClass]="{'active_route': PePo.currentLang == 'ar'}">{{'Arabic' | translate}}</a></li>
              </ul>
            </li> -->
          </ul>

        </div>
      </div>
    </nav>
  </header>


</ng-container>

<!-- <ul class="nw_submenu_items language_menu" style="position: fixed;
top: 100px;
background: red;
padding: 50px;">
  <li> <button class="english" (click)="setLanguage('en')" *ngIf="PePo.currentLang == 'ar'" style="font-size: 20px;">English</button></li>
  <li> <button class="arabic" (click)="setLanguage('ar')" *ngIf="PePo.currentLang == 'en'"  style="font-size: 20px; font-family: cairo;" >عربى</button></li>
</ul> -->

<router-outlet></router-outlet>


<app-contact-us
  *ngIf="routeLink !== '/' && routeLink !='/Events' && routeLink !='/EventsFormSharing' && !this.routeLink.includes('Engagement/video_preview') ">
</app-contact-us>
<!-- FOOTER -->
<div class="nw_footer" *ngIf="routeLink !== '/' && !this.routeLink.includes('Engagement/video_preview') ">
  <div class="nw_post">
    <div class="container">
      <div class="col-sm-6 row">
        <div id="footer_logo">
          <img src="assets/images/Arabic_blue.png" [routerLink]="'/'" style="margin-top: 5px; cursor: pointer;"
            alt="symbios">
        </div>

        <div class="nw_footer_description">
          {{'Footer.FollowUS' | translate}}
        </div>
        <div class="nw_footer_social">
          <!-- <ul>
            <li><a href="https://www.facebook.com/symbiosconsulting2005/" target="_blank"><i class="fa fa-facebook"></i></a></li>
            <li><a href="https://twitter.com/symbios_group" target="_blank"><i class="fa fa-twitter"></i></a></li>
            <li><a href="https://www.youtube.com/channel/UCx7HKNnSIlEEE5JKI4JsA4A" target="_blank"><i class="fa fa-youtube"></i></a></li>
            <li><a href="https://www.linkedin.com/company/symbios-consulting" target="_blank"><i class="fa fa-linkedin"></i></a></li>
          </ul> -->
          <ul>
            <!-- <li><a [href]="Footer_Sectios?.socialMedia?.facebook" target="_blank"><i class="fa fa-facebook"></i></a></li> -->
            <li><a [href]="Footer_Sectios?.socialMedia?.twitter" target="_blank"><i class="fa fa-twitter"></i></a></li>
            <!-- <li><a [href]="Footer_Sectios?.socialMedia?.youtube" target="_blank"><i class="fa fa-youtube"></i></a></li> -->
            <li><a [href]="Footer_Sectios?.socialMedia?.linkedIn" target="_blank"><i class="fa fa-linkedin"></i></a>
            </li>
          </ul>
        </div>
      </div>

      <div class="col-sm-6 addresses">
        <div class="first_address">
          <div class="footer_title">
            {{'getInTouch' | translate}}
          </div>
          <div class="footer_line">
          </div>

          <!-- <div class="footer_address">
            <p>Jeddah Office Address :Building 1, Fourth floor; Emmar Square, Al Faihaa,
              Jeddah, 22241 Saudi Arabia

            </p>
          </div>
          <div class="footer_address">
            <p>Riyadh Office Address :Ground Floor, Levels 1 & 2, Building S4 Riyadh Business Front, Airport Road |
              Riyadh, Saudi Arabia
            </p>
          </div> -->
          <ng-container *ngFor="let item of Footer_Sectios?.addresses">
            <p class="footer_address" [innerText]="PePo.currentLang == 'en'? item?.name?.en :item?.name?.ar"></p>
          </ng-container>
        </div>

        <div class="second_address">
          <!-- <div class="footer_phone_number">
            +966 55 503 5737
          </div>
          <div class="footer_phone_number">
            +966 54 222 3450
          </div>
          <div class="footer_phone_number">
            +966 53 511 8451
          </div> -->

          <ng-container *ngFor="let item of Footer_Sectios?.contacts">
            <span style="display: block; margin-block: 2px; direction: ltr;">{{item}}</span>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
  <div class="nw_rights">
    <div class="container">
      <p>{{'CopyRight' | translate }}</p>
    </div>
  </div>
</div>
