<div class="container-fluid nw_page_content nw_about_us">
  <div class="container nw_post nw_type_2 nw_container">
    <div class="story" *ngFor="let item of PageData">
      <div class="nw_post_title">
        <div class="nw_subtitle wow fadeIn">
          <p [innerText]="item['header'][_pepo.currentLang]"></p>
        </div>
        <div class="nw_title wow fadeIn">
          <h1 [innerText]="item['title'][_pepo.currentLang]"></h1>
        </div>
        <div class="nw_title_line wow fadeIn">
        </div>
      </div>

      <div class="nw_page_about">
        <div class="containter">
          <div class="containter-flex">
            <div class="nw_page_about_cont1  wow fadeIn">
              <p [innerHtml]="item['text'][_pepo.currentLang]">
              </p>
            </div>
            <div class="nw-about-us-video-img  wow fadeIn">
              <div class="mt_video text-center row">
                <div class="wow fadeInUp animated hc-visible">
                  <img [src]="item['image']" alt="">
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>

    </div>
  </div>
</div>
