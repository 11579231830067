import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('./modules/new-home-page/new-home-page.module').then(
        (m) => m.NewHomePageModule
      ),
  },
  {
    path: 'about-us',
    loadChildren: () =>
      import('./modules/about-us/about-us.module').then((m) => m.AboutUsModule),
  },
  {
    path: 'partners',
    loadChildren: () =>
      import('./modules/partners/partners.module').then(
        (m) => m.PartnersModule
      ),
  },
  {
    path: 'why-us',
    loadChildren: () =>
      import('./modules/why-us/why-us.module').then((m) => m.WhyUsModule),
  },
  {
    path: 'approach',
    loadChildren: () =>
      import('./modules/approach/approach.module').then(
        (m) => m.ApproachModule
      ),
  },
  {
    path: 'achievements',
    loadChildren: () =>
      import('./modules/achievements/achievements.module').then(
        (m) => m.AchievementsModule
      ),
  },
  {
    path: 'team',
    loadChildren: () =>
      import('./modules/team/team.module').then((m) => m.TeamModule),
  },
  {
    path: 'contact-us',
    loadChildren: () =>
      import('./modules/contact-us/contact-us.module').then(
        (m) => m.ContactUsModule
      ),
  },
  {
    path: 'Blog',
    loadChildren: () =>
      import('./modules/blog/blog.module').then((m) => m.BlogModule),
  },
  {
    path: 'newsRooms',
    loadChildren: () =>
      import('./modules/news-rooms/news-rooms.module').then(
        (m) => m.NewsRoomsModule
      ),
  },
  {
    path: 'success-stories',
    loadChildren: () =>
      import('./modules/success-stories/success-stories.module').then(
        (m) => m.SuccessStoriesModule
      ),
  },
  {
    path: 'business-excellence',
    loadChildren: () =>
      import(
        './modules/services/business-exellence/business-exellence.module'
      ).then((m) => m.BusinessExellenceModule),
  },
  {
    path: 'cost-optimization',
    loadChildren: () =>
      import(
        './modules/services/cost-optimization/cost-optimization.module'
      ).then((m) => m.CostOptimizationModule),
  },
  {
    path: 'operational-excellence',
    loadChildren: () =>
      import(
        './modules/services/operational-excellence/operational-excellence.module'
      ).then((m) => m.OperationalExcellenceModule),
  },
  {
    path: 'supply-chain-optimization',
    loadChildren: () =>
      import(
        './modules/services/supplychaine-optimization/supplychaine-optimization.module'
      ).then((m) => m.SupplychaineOptimizationModule),
  },
  {
    path: 'digital-transformation',
    loadChildren: () =>
      import(
        './modules/services/digital-transformation/digital-transformation.module'
      ).then((m) => m.DigitalTransformationModule),
  },
  {
    path: 'business-reEngineering',
    loadChildren: () =>
      import(
        './modules/services/business-re-engineering/business-re-engineering.module'
      ).then((m) => m.BusinessReEngineeringModule),
  },
  {
    path: 'human-resource',
    loadChildren: () =>
      import('./modules/services/human-resource/human-resource.module').then(
        (m) => m.HumanResourceModule
      ),
  },
  {
    path: 'training-workshops',
    loadChildren: () =>
      import(
        './modules/services/training-workshops/training-workshops.module'
      ).then((m) => m.TrainingWorkshopsModule),
  },
  {
    path: 'industries',
    loadChildren: () =>
      import('./modules/people/industries/industries.module').then(
        (m) => m.IndustriesModule
      ),
  },
  {
    path: 'return-on-investment',
    loadChildren: () =>
      import(
        './modules/people/return-on-investment/return-on-investment.module'
      ).then((m) => m.ReturnOnInvestmentModule),
  },
  {
    path: 'testimonials',
    loadChildren: () =>
      import('./modules/people/testimonials/testimonials.module').then(
        (m) => m.TestimonialsModule
      ),
  },
  {
    path: 'Alumni',
    loadChildren: () =>
      import('./modules/people/alumni/alumni.module').then(
        (m) => m.AlumniModule
      ),
  },
  {
    path: 'insight',
    loadChildren: () =>
      import('./modules/learn/insight/insight.module').then(
        (m) => m.InsightModule
      ),
  },
  {
    path: 'Engagement',
    loadChildren: () =>
      import('./modules/learn/videos/videos.module').then(
        (m) => m.VideosModule
      ),
  },
  {
    path: 'gallery',
    loadChildren: () =>
      import('./modules/learn/gallery/gallery.module').then(
        (m) => m.GalleryModule
      ),
  },
  {
    path: 'webinars',
    loadChildren: () =>
      import('./modules/learn/webinars/webinars.module').then(
        (m) => m.WebinarsModule
      ),
  },
  {
    path: 'Books',
    loadChildren: () =>
      import('./modules/learn/books/books.module').then((m) => m.BooksModule),
  },
  {
    path: 'vison2030',
    loadChildren: () =>
      import('./modules/vison2030/vison2030.module').then(
        (m) => m.Vison2030Module
      ),
  },
  {
    path: 'Courses-booking',
    loadChildren: () =>
      import('./modules/courses-booking/courses-booking.module').then(
        (m) => m.CoursesBookingModule
      ),
  },
  {
    path: 'newHomePage',
    loadChildren: () =>
      import('./modules/new-home-page/new-home-page.module').then(
        (m) => m.NewHomePageModule
      ),
  },
  {
    path: 'Clients',
    loadChildren: () =>
      import('./modules/clients/clients.module').then((m) => m.ClientsModule),
  },
  { path: 'case-studies', loadChildren: () => import('./modules/case-studies/case-studies.module').then(m => m.CaseStudiesModule) },
  { path: 'study-tours', loadChildren: () => import('./modules/learn/study-tours/study-tours.module').then(m => m.StudyToursModule) },
  { path: 'EventsFormSharing', loadChildren: () => import('./modules/events-form-sharing/events-form-sharing.module').then(m => m.EventsFormSharingModule) },
  { path: 'Events', loadChildren: () => import('./modules/events/events.module').then(m => m.EventsModule) },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  // imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled', initialNavigation: 'enabled' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
