<div class="popUpFormModel" *ngIf="visibility == true">
  <div class="nw_get_consultation">
    <!-- <h3> Get a Consultation </h3> -->
    <div class="popUp_header">
      <p>Please Fill these fields to continue.</p>
      <button id="closeBtn" (click)="close()"><i class="fas fa-window-close"></i></button>
    </div>
    <div class="nw_contact_us_fill">
      <form [formGroup]="form">
        <div class=" col-lg-12 form-group">
          <input class="form-control" name="full-name" value="" size="40" aria-required="true" aria-invalid="false"
            placeholder="Full Name" type="text" formControlName="FullName"
            [class.is-invalid]="form.get('FullName')?.invalid && form.get('FullName')?.touched">


          <div class="validation"
            *ngIf="form.get('FullName')?.invalid && form.get('FullName')?.touched && form.get('FullName')?.dirty">
            <small class="text-danger" *ngIf="form.get('FullName')?.errors?.required">Full name is
              required</small>
          </div>
          <div class="validation"
            *ngIf="form.get('FullName')?.invalid && form.get('FullName')?.touched && form.get('FullName')?.dirty">
            <small class="text-danger" *ngIf="form.get('FullName')?.errors?.nameContainNumber">Name cannot contain numbers</small>
          </div>
        </div>
        <div class=" col-lg-12 form-group">
          <input class="form-control" name="email" value="" size="40" aria-required="true" aria-invalid="false"
            placeholder="Email Address" type="email" formControlName="email"
            [class.is-invalid]="getEmail().invalid && getEmail().touched">
          <div class="validation"
            *ngIf="form.get('email')?.invalid && form.get('email')?.touched && form.get('email')?.dirty">
            <small class="text-danger" *ngIf="form.get('email')?.errors?.required">email address is
              required</small>
            <small class="text-danger" *ngIf="form.get('email')?.errors?.email">Enter a valid email</small>
          </div>
        </div>
        <div class=" col-lg-12 form-group">
          <input class="form-control" name="Phone" value="" size="40" aria-required="true" aria-invalid="false"
            placeholder="Phone" type="text" formControlName="Phone"
            [class.is-invalid]="form.get('Phone')?.invalid && form.get('Phone')?.touched">

          <div class="validation"
            *ngIf="form.get('Phone')?.invalid && form.get('Phone')?.touched && form.get('Phone')?.dirty">
            <small class="text-danger" *ngIf="form.get('Phone')?.errors?.required">Phone is
              required</small>
          </div>
          <div class="validation"
            *ngIf="form.get('Phone')?.invalid && form.get('Phone')?.touched && form.get('Phone')?.dirty">
            <small class="text-danger" *ngIf="form.get('Phone')?.errors?.phoneError">Enter a valid Phone number </small>
          </div>

        </div>

        <div class=" col-lg-12 form-group">
          <input class="form-control" name="organization" value="" size="40" aria-required="true" aria-invalid="false"
            placeholder="organization" type="text" formControlName="organization"
            [class.is-invalid]="form.get('organization')?.invalid && form.get('organization')?.touched">

          <div class="validation"
            *ngIf="form.get('organization')?.invalid && form.get('organization')?.touched && form.get('organization')?.dirty">
            <small class="text-danger" *ngIf="form.get('organization')?.errors?.required">organization is
              required</small>
          </div>

        </div>

        <div class="col-md-12">
          <button class="nw_submit" type="submit" (click)="submitForm()">SEND <i class="fa fa-long-arrow-right"
              aria-hidden="true"></i></button><span></span>
        </div>
      </form>
    </div>
  </div>
</div>
