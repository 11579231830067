<div class="panel">
  <div class="panel-heading">
    <h2>  Symbios Services </h2>
  </div>
  <div class="panel-body">
    <div class="menu-services-sidebar-container">
      <ul id="menu-services-sidebar" class="menu">
        <li class="menu-item" routerLink="/business-excellence"><a>Business Excellence</a></li>
        <li class="menu-item" routerLink="/cost-optimization"><a>Cost Optimization & Operational Excellence</a>
        </li>
        <li class="menu-item" routerLink="/supply-chain-optimization"><a>Supply Chain Optimization</a></li>
        <li class="menu-item" routerLink="/digital-transformation"><a>Digital Transformation</a></li>
        <li class="menu-item" routerLink="/business-reEngineering"><a>Business Re-Engineering</a></li>
        <li class="menu-item" routerLink="/human-resource"><a>Human Resources</a></li>
        <li class="menu-item" routerLink="/training-workshops"><a>Training & Workshops</a></li>
      </ul>
    </div>
  </div>
</div>
