import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { httpErrorContent } from '../utils/httpError.interface';

@Injectable({
  providedIn: 'root',
})
export class requestInterceptor implements HttpInterceptor {
  constructor(private _toster: ToastrService) {}
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(req);
    // return next.handle(req).pipe(
    //   catchError((_error: httpErrorContent) => {
    //     if (_error) {
    //       this._toster.error(_error.message, 'Error Message');
    //     }
    //     return throwError(_error);
    //   })
    // );
  }
}
