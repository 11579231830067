export const environment = {
  production: true,
  clientId:"Y29tLnN5bWJpb3MuYXBwcy5zaXRhcA==",
  serverUrl: "https://symbios-ksa.co/v1",
  firebase : {
    apiKey: "AIzaSyBjOU_k5PMkDaQFrfYVCwsNBvLXOjfPLt0",
    authDomain: "symbios-ksa.firebaseapp.com",
    projectId: "symbios-ksa",
    storageBucket: "symbios-ksa.appspot.com",
    messagingSenderId: "446128398959",
    appId: "1:446128398959:web:c18d442106e92631c2639a",
    measurementId: "G-R0EZJC3Z2R"
  }
};
