import { MatBadgeModule } from '@angular/material/badge';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatSortModule } from '@angular/material/sort';
import { MatCardModule } from '@angular/material/card';
import { MatListModule } from '@angular/material/list';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatStepperModule } from '@angular/material/stepper';
import { MatRadioModule } from '@angular/material/radio';
import { MatDividerModule } from '@angular/material/divider';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTableModule } from '@angular/material/table';
import { MatMenuModule } from '@angular/material/menu';
import { MatChipsModule } from '@angular/material/chips';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatNativeDateModule } from '@angular/material/core';
import {
  MatDialogModule,
  MAT_DIALOG_DATA,
  MatDialogRef,
} from '@angular/material/dialog';
// import { MatSnackBarModule } from '@angular/material/snack-bar';
import {MatSnackBarModule} from '@angular/material/snack-bar';

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BreadcrumbsComponent } from './breadcrumbs/breadcrumbs.component';
import { TranslateModule } from '@ngx-translate/core';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { SidebarPanelComponent } from './sidebar-panel/sidebar-panel.component';
import { PopUpFormComponent } from './pop-up-form/pop-up-form.component';
import { ToastrModule } from 'ngx-toastr';
import { SectionHeadComponent } from './section-head/section-head.component';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { DownloadFormComponent } from './download-form/download-form.component';
import { SharedCollapseComponent } from './shared-collapse/shared-collapse.component';
import { ShareButtonsModule } from 'ngx-sharebuttons/buttons';
import { ShareIconsModule } from 'ngx-sharebuttons/icons';
import { SocialLinkButtonsComponent } from './social-link-buttons/social-link-buttons.component';
import { InnerHeaderComponent } from './inner-header/inner-header.component';
import { GloabalPageComponent } from './gloabal-page/gloabal-page.component';
import { MyBootstrapModalComponent } from './my-bootstrap-modal/my-bootstrap-modal.component';
import { EmptyDataComponent } from './empty-data/empty-data.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { RouterModule } from '@angular/router';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    ShareButtonsModule,
    ShareIconsModule,
    ToastrModule.forRoot({
      timeOut: 3000,
      positionClass: 'toast-top-left',
      preventDuplicates: true,
    }),
    MatSnackBarModule,
    TranslateModule,
    FormsModule,
    NgSelectModule,
    RouterModule
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    BreadcrumbsComponent,
    ContactUsComponent,
    TranslateModule,
    SidebarPanelComponent,
    PopUpFormComponent,
    ToastrModule,
    SectionHeadComponent,
    CarouselModule,
    DownloadFormComponent,
    SharedCollapseComponent,
    ShareButtonsModule,
    ShareIconsModule,
    SocialLinkButtonsComponent,
    NgSelectModule,

    // matertial import
    MatIconModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatInputModule,
    MatTooltipModule,
    MatSelectModule,
    MatButtonModule,
    MatChipsModule,
    MatMenuModule,
    MatDialogModule,
    MatTableModule,
    MatTabsModule,
    MatDividerModule,
    MatTableModule,
    MatTabsModule,
    MatRadioModule,
    MatStepperModule,
    MatInputModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatPaginatorModule,
    MatProgressSpinnerModule,
    // MatSnackBarModule,
    MatExpansionModule,
    MatProgressBarModule,
    MatNativeDateModule,
    MatSidenavModule,
    MatToolbarModule,
    MatListModule,
    MatCardModule,
    MatSortModule,
    MatAutocompleteModule,
    MatSlideToggleModule,
    MatGridListModule,
    MatBadgeModule,
    MatSnackBarModule,
    GloabalPageComponent,
    EmptyDataComponent
  ],
  providers: [
    { provide: MAT_DIALOG_DATA, useValue: {} },
    { provide: MatDialogRef, useValue: {} },
  ],
  declarations: [
    BreadcrumbsComponent,
    ContactUsComponent,
    SidebarPanelComponent,
    PopUpFormComponent,
    SectionHeadComponent,
    DownloadFormComponent,
    SharedCollapseComponent,
    SocialLinkButtonsComponent,
    InnerHeaderComponent,
    GloabalPageComponent,
    EmptyDataComponent,
  ],
})
export class SharedModule {}
