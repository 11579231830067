<ng-container *ngIf="typeServiceProp$ | async"></ng-container>
<ng-container *ngIf="getTypeService$ | async"></ng-container>
<!-- POST TYPE 9 -->
<div class="nw_post nw_type_9 nw_container">
  <!--CONTACT US -->
  <div class="nw_contact_us">
    <div class="container nw_contact_us_container">
      <div class="col-md-6">
        <div class="nw_contact_us_fill row">
          <form [formGroup]="form" autocomplete="off">
            <div class="row">
              <div class="col-md-6 form-group">
                <input
                  class="form-control"
                  name="fullName"
                  value=""
                  size="40"
                  aria-required="true"
                  aria-invalid="false"
                  placeholder="{{ 'fullName' | translate }}"
                  type="text"
                  formControlName="fullName"
                  [class.is-invalid]="
                    form.get('fullName')?.invalid &&
                    form.get('fullName')?.touched
                  "
                />
                <!-- <div class="validation"
                  *ngIf="form.get('fullName')?.invalid && form.get('fullName')?.touched && form.get('fullName')?.dirty">
                  <small class="text-danger" *ngIf="form.get('fullName')?.errors?.required">First name is required</small>
                </div> -->

                <div
                  class="validation"
                  *ngIf="
                    form.get('fullName')?.invalid &&
                    form.get('fullName')?.touched &&
                    form.get('fullName')?.dirty
                  "
                >
                  <small
                    class="text-danger"
                    *ngIf="form.get('fullName')?.errors?.required"
                    >{{ "Footer.FnameReq" | translate }}</small
                  >
                  <small
                    class="text-danger"
                    *ngIf="form.get('fullName')?.errors?.pattern"
                    >{{ "Footer.RemoveSPace" | translate }}</small
                  >
                </div>
              </div>
              <div class="col-md-6 form-group">
                <input
                  class="form-control"
                  name="phone"
                  value=""
                  size="40"
                  aria-required="true"
                  aria-invalid="false"
                  placeholder="{{ 'phone' | translate }}"
                  type="text"
                  formControlName="phone"
                  [class.is-invalid]="
                    form.get('phone')?.invalid &&
                    form.get('phone')?.touched
                  "
                />

                <!-- <div class="validation"
                  *ngIf="form.get('phone')?.invalid && form.get('phone')?.touched && form.get('phone')?.dirty">
                  <small class="text-danger" *ngIf="form.get('phone')?.errors?.required">last name is
                    required</small>
                </div> -->

                <div
                  class="validation"
                  *ngIf="
                    form.get('phone')?.invalid &&
                    form.get('phone')?.touched &&
                    form.get('phone')?.dirty
                  "
                >
                  <small
                    class="text-danger"
                    *ngIf="form.get('phone')?.errors?.required"
                    >{{ "Footer.LnameReq" | translate }}</small
                  >
                  <small
                    class="text-danger"
                    *ngIf="form.get('phone')?.errors?.pattern"
                    >{{ "Footer.RemoveSPace" | translate }}</small
                  >
                </div>
              </div>
              <div class="col-md-6 form-group">
                <input
                  class="form-control"
                  name="email"
                  value=""
                  size="40"
                  aria-required="true"
                  aria-invalid="false"
                  placeholder="{{ 'Email_Address' | translate }}"
                  type="email"
                  formControlName="email"
                  [class.is-invalid]="getEmail().invalid && getEmail().touched"
                />
                <!-- <div class="validation"
                  *ngIf="form.get('email')?.invalid && form.get('email')?.touched && form.get('email')?.dirty">
                  <small class="text-danger" *ngIf="form.get('email')?.errors?.required">email address is
                    required</small>
                  <small class="text-danger" *ngIf="form.get('email')?.errors?.email">Enter a valid email</small>
                </div> -->
                <div
                  class="validation"
                  *ngIf="
                    form.get('email')?.invalid &&
                    form.get('email')?.touched &&
                    form.get('email')?.dirty
                  "
                >
                  <small
                    class="text-danger"
                    *ngIf="form.get('email')?.errors?.required"
                    >{{ "Footer.EmailReq" | translate }}</small
                  >
                  <small
                    class="text-danger"
                    *ngIf="form.get('email')?.errors?.email"
                    >{{ "Footer.ValidEmail" | translate }}</small
                  >
                </div>
              </div>
              <div class="col-md-6 form-group">
                <input
                  class="form-control"
                  name="jobPosition"
                  value=""
                  size="40"
                  aria-required="true"
                  aria-invalid="false"
                  placeholder="{{ 'jobPosition' | translate }}"
                  type="text"
                  formControlName="jobPosition"
                  [class.is-invalid]="
                    form.get('jobPosition')?.invalid && form.get('jobPosition')?.touched
                  "
                />

                <!-- <div class="validation"
                  *ngIf="form.get('jobPosition')?.invalid && form.get('jobPosition')?.touched && form.get('jobPosition')?.dirty">
                  <small class="text-danger" *ngIf="form.get('jobPosition')?.errors?.required">jobPosition is
                    required</small>
                </div> -->

                <div
                  class="validation"
                  *ngIf="
                    form.get('jobPosition')?.invalid &&
                    form.get('jobPosition')?.touched &&
                    form.get('jobPosition')?.dirty
                  "
                >
                  <small
                    class="text-danger"
                    *ngIf="form.get('jobPosition')?.errors?.required"
                    >{{ "Footer.SubReq" | translate }}</small
                  >
                  <small
                    class="text-danger"
                    *ngIf="form.get('jobPosition')?.errors?.pattern"
                    >{{ "Footer.RemoveSPace" | translate }}</small
                  >
                </div>
              </div>
              <div class="col-md-6 form-group">
                <input
                  class="form-control"
                  name="company"
                  value=""
                  size="40"
                  aria-required="true"
                  aria-invalid="false"
                  placeholder="{{ 'company' | translate }}"
                  type="text"
                  formControlName="company"
                  [class.is-invalid]="
                    form.get('company')?.invalid && form.get('company')?.touched
                  "
                />

                <!-- <div class="validation"
                  *ngIf="form.get('company')?.invalid && form.get('company')?.touched && form.get('company')?.dirty">
                  <small class="text-danger" *ngIf="form.get('company')?.errors?.required">company is
                    required</small>
                </div> -->

                <div
                  class="validation"
                  *ngIf="
                    form.get('company')?.invalid &&
                    form.get('company')?.touched &&
                    form.get('company')?.dirty
                  "
                >
                  <small
                    class="text-danger"
                    *ngIf="form.get('company')?.errors?.required"
                    >{{ "Footer.SubReq" | translate }}</small
                  >
                  <small
                    class="text-danger"
                    *ngIf="form.get('company')?.errors?.pattern"
                    >{{ "Footer.RemoveSPace" | translate }}</small
                  >
                </div>
              </div>

            </div>
            <div class="row">
              <div class="col-md-12 form-group mx-inline-block" style="float: none;">
                <ng-select formControlName="typeService" placeholder="{{'Footer.servicesType'|translate}}" [items]="typeOfServicesItems"
                  [bindLabel]="'text.'+translate.currentLang" bindValue="_id">
                </ng-select>
              </div>

              <div class="col-md-12 form-group mx-inline-block" style="float: none;" *ngIf="trainingCategoryItems.length > 0">
                <ng-select formControlName="trainingCategory" placeholder="{{'Footer.trainingCategory'|translate}}"  [items]="trainingCategoryItems"
                  [bindLabel]="'trainingCategory.'+translate.currentLang" bindValue="_id">
                </ng-select>
              </div>

              <div class="col-md-12 form-group mx-inline-block" style="float: none;" *ngIf="sigmaTrainingItems.length > 0">
                <ng-select formControlName="sigmaTraining" [placeholder]="trainingCategoryProp.value"  placeholder="{{'Footer.training'|translate}}" [items]="sigmaTrainingItems"
                  [bindLabel]="'name.'+translate.currentLang" bindValue="_id">
                </ng-select>
              </div>

              <div class="col-md-12 form-group mx-inline-block" style="float: none;">
                <ng-select formControlName="knownUs"  placeholder="{{'Footer.knownUs'|translate}}"   [items]="knownUsItems"
                  bindLabel="text" bindValue="text">
                </ng-select>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12 form-group">
                <textarea
                  class="form-control"
                  name="message"
                  cols="40"
                  rows="4"
                  aria-invalid="false"
                  placeholder="{{ 'Your_Message' | translate }}"
                  formControlName="message"
                  [class.is-invalid]="
                    form.get('message')?.invalid && form.get('message')?.touched
                  "
                ></textarea>

                <!-- <div class="validation"
                  *ngIf="form.get('message')?.invalid && form.get('message')?.touched && form.get('message')?.dirty">
                  <small class="text-danger" *ngIf="form.get('message')?.errors?.required">message is
                    required</small>
                </div> -->
                <div
                  class="validation"
                  *ngIf="
                    form.get('message')?.invalid &&
                    form.get('message')?.touched &&
                    form.get('message')?.dirty
                  "
                >
                  <small
                    class="text-danger"
                    *ngIf="form.get('message')?.errors?.required"
                    >{{ "Footer.MessageReq" | translate }}</small
                  >
                  <small
                    class="text-danger"
                    *ngIf="form.get('message')?.errors?.pattern"
                    >{{ "Footer.RemoveSPace" | translate }}</small
                  >
                </div>
              </div>
              <div class="col-md-12">
                <button
                  style="cursor: pointer"
                  class="nw_submit submit_homePage"
                  type="submit"
                  [disabled]="form.invalid"
                  (click)="submitForm()"
                >
                  {{ "Footer.SendMSG" | translate }}</button
                ><span></span>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div class="col-md-6">
        <div class="nw_title">
          <h2 style="margin: 0">{{ "Footer.contactForInF" | translate }}.</h2>
        </div>
        <div class="nw_empty"></div>
        <div class="nw_subtitle">
          <p>{{ "Footer.CallUs" | translate }}</p>
        </div>
        <div class="nw_title">
          <h4
            style="color: #fff; direction: ltr"
            *ngFor="let item of this.Footer_Sectios?.contacts"
          >
            <!-- <span>Riyadh Office : </span> +966 11 827 4289 -->
            <span>{{ item }}</span>
          </h4>
        </div>
      </div>
    </div>
  </div>
</div>
