import { EventEmitter, Component, OnInit, Output } from '@angular/core';
import {
  FormGroup,
  FormBuilder,
  Validators,
  FormControl,
} from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { NameValidation } from './validation_name_contain_number.validation';
import { phoneValidation } from './validation_number.validator';

@Component({
  selector: 'app-pop-up-form',
  templateUrl: './pop-up-form.component.html',
  styleUrls: ['./pop-up-form.component.scss'],
})
export class PopUpFormComponent implements OnInit {
  @Output() sendSubmit: EventEmitter<any> = new EventEmitter();
  visibility: boolean = true;
  form: FormGroup = new FormGroup({});
  constructor(
    private _formBuilder: FormBuilder,
    private toaster: ToastrService
  ) {
    this.checkLocalStorage();
  }

  ngOnInit(): void {
    this.initForm();
  }

  checkLocalStorage() {
    let userEmail = localStorage.getItem('userEmail');
    userEmail ? (this.visibility = false) : (this.visibility = true);
  }

  initForm() {
    this.form = this._formBuilder.group({
      FullName: this._formBuilder.control('', [Validators.required,NameValidation(/^[a-zA-Z ]*$/)]),
      Phone: this._formBuilder.control('', [Validators.required,phoneValidation(/^[0-9]+(\.?[0-9]+)?$/)]),
      organization: this._formBuilder.control('', Validators.required),
      email: this._formBuilder.control('', [
        Validators.email,
        Validators.required,
      ]),
    });
  }

  getEmail(): FormControl {
    return this.form.get('email') as FormControl;
  }
  submitForm() {
    if (this.form.invalid) {
      this.toaster.error('please fill all fields');
    } else {
      console.log(this.form.get('email').value);
      localStorage.setItem('userEmail', this.form.get('email').value);
      this.toaster.success('Thanks for your time');
      this.form.reset();
      this.sendSubmit.emit('change visibility');
    }
  }

  close() {
    this.visibility = false;
  }
}
