import { debounceTime, switchMap, tap } from 'rxjs/operators';
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
} from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { ThrowStmt } from '@angular/compiler';
import { ToastrService } from 'ngx-toastr';
import { HttpClient } from '@angular/common/http';
import { ApiDataService } from 'src/@PePo/utils/api-data.service';
import { PePoService } from 'src/@PePo/utils/PePo.service';
import { API_URLS } from 'src/assets/constants/API_URLS';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HomePageService } from 'src/app/modules/new-home-page/home-page.service';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss'],
})
export class ContactUsComponent implements OnInit {
  form: FormGroup = new FormGroup({});
  googleSheetUrl = 'https://sheet.best/api/sheets/ebc838a7-5dc9-4adb-8481-2974efec8b35';
  api_url= environment.serverUrl;
  typeServiceProp$ : Observable<any> | null = null;
  typeOfServicesItems: any[]=[];
  getTypeService$: Observable<any> | null= null;
  sigmaTrainingItems:  any[]=[];
  trainingCategoryItems:  any[]=[];

  knownUsItems = [
      {
        id: 1,
        text: 'Facebook'
      }, {
        id:2,
        text: 'twitter'
      }, {
        id: 3,
        text: 'LinkedIn'
      }
    ]

  constructor(
    private _formBuilder: FormBuilder,
    private toaster: ToastrService,
    private _http: HttpClient,
    private _apiData:ApiDataService,
    private _PePo:PePoService,
    private _homePage: HomePageService,
    public translate:TranslateService

  ) {}

  ngOnInit(): void {
    this.initForm();
    this.FooterSections()
    this.typeServiceProp$ =  this.typeServiceProp.valueChanges.pipe(
     debounceTime(200),
     switchMap(value =>{

        return value? this._http.get(this.api_url+'/web/'+value): of([])
     }),
     tap(data=>{
      console.log('data re',data);
      if (data) {
        // this.form.addControl('')
      }
    })
    );

    this.typeServiceProp$ =  this.typeServiceProp.valueChanges.pipe(
      debounceTime(200),
      switchMap(value =>{

         return value? this._homePage.getTypeServiceById(value): of([])
      }),
      tap(data=>{
       if (data['body']) {
         this.trainingCategoryItems = data['body']['trainingCategories']
       }
     })
     );

     this.trainingCategoryProp.valueChanges.subscribe((value)=>{
      this.sigmaTrainingItems = this.trainingCategoryItems?.find(e=>{
        if (e._id == value) {
          return e
        }
      }).sigmaTraining;

     })
    this.getTypeServiceArray()
  }



  public get trainingCategoryProp() : FormControl {
    return this.form.get('trainingCategory') as FormControl;
  }

  initForm() {
    this.form = this._formBuilder.group({
      fullName: this._formBuilder.control('', [Validators.required, Validators.pattern('^(?! )[A-Za-z ]*(?<! )$')]),
      phone: this._formBuilder.control('', Validators.required),
      jobPosition: this._formBuilder.control(null),
      email: this._formBuilder.control('',
        [Validators.email, Validators.required],
      ),
      company: this._formBuilder.control(null),
      knownUs: this._formBuilder.control(null,Validators.required),
      typeService: this._formBuilder.control(null, Validators.required),
      trainingCategory: this._formBuilder.control('',Validators.required),
      sigmaTraining: this._formBuilder.control('',Validators.required),
      message: this._formBuilder.control('', [Validators.required]),
    });
  }

  getEmail(): FormControl {
    return this.form.get('email') as FormControl;
  }


  public get typeServiceProp() : FormControl {
    return this.form.get('typeService') as FormControl;
  }


  submitForm() {
    if (this.form.invalid) {
      this.toaster.error(this.translate.instant('Fields_required'));
    } else {
      // Sumbit form
      const Payload = this.form.value;
      console.log(Payload)
      // this._http.post(this.googleSheetUrl, Payload)?.subscribe((res) => {
        this._apiData.post(API_URLS.Contact_us.post,Payload,'',this._PePo.AuthHeaders)?.subscribe((res) => {
        console.log(res)
        this.toaster.success(this.translate.instant('Thanks'));
        this.form.reset();
      });
    }
  }

  Footer_Sectios: any;
  FooterSections() {
    this._apiData.getData(API_URLS.Footer.getWeb,this._PePo.AuthHeaders)?.subscribe((res) => {
      this.Footer_Sectios = res['body'][0]
      console.log(this.Footer_Sectios.contacts)
    })
  }

  getTypeServiceArray(){
    this.getTypeService$ = this._homePage.getTypeService().pipe(
      tap((res)=>{
        console.log({res});
        this.typeOfServicesItems = res['body']
      })
    )
  }

}
