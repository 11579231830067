import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-shared-collapse',
  templateUrl: './shared-collapse.component.html',
  styleUrls: ['./shared-collapse.component.scss']
})
export class SharedCollapseComponent implements OnInit {
@Input() Header:any;
@Input() body_img:any;
@Input() body_Txt:any;
@Input() body_html:any;
@Input() data:any;
@Input() lang:string;
  constructor() { }

  ngOnInit(): void {
  }

  expand_collapse(event:any){
    event.target.parentElement.classList.toggle('active')
  }
}
