import { HttpClient } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { Component, ElementRef, Input, Renderer2, ViewChild } from '@angular/core';
import {
  NavigationEnd,
  NavigationStart,
  Router,
  RouterEvent,
} from '@angular/router';
import { PePoService } from 'src/@PePo/utils/PePo.service';
import { ApiDataService } from 'src/@PePo/utils/api-data.service';
import { API_URLS } from 'src/assets/constants/API_URLS';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'symbios-ksa';
  routeLink: any;
  selectedLanguage: any;
  innerWidth: any;
  company_active: boolean = false;
  service_active: boolean = false;
  our_stories_active: boolean = false;
  public showOverlay = true;

  constructor(
    private router: Router,
    public PePo: PePoService,
    public _apidata: ApiDataService,
    public translate: TranslateService,
    private renderer: Renderer2
  ) {
    this.routeLink = this.router.routerState.snapshot.url;
    router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.routeLink = event.url;
        console.log(this.routeLink);
        if (
          this.routeLink == '/why-us' ||
          this.routeLink == '/about-us' ||
          this.routeLink == '/partners' ||
          this.routeLink == '/approach' ||
          this.routeLink == '/achievements' ||
          this.routeLink == '/contact-us'
        ) {
          this.company_active = true;
          this.service_active = false;
          this.our_stories_active = false;
        } else if (
          this.routeLink == '/business-excellence' ||
          this.routeLink == '/cost-optimization' ||
          this.routeLink == '/supply-chain-optimization' ||
          this.routeLink == '/digital-transformation' ||
          this.routeLink == '/business-reEngineering' ||
          this.routeLink == '/human-resource' ||
          this.routeLink == '/training-workshops'
        ) {
          this.service_active = true;
          this.company_active = false;
          this.our_stories_active = false;
        } else if (
          this.routeLink == '/industries' ||
          this.routeLink == '/return-on-investment' ||
          this.routeLink == '/Clients' ||
          this.routeLink == '/gallery' ||
          this.routeLink == '/Engagement' ||
          this.routeLink == '/case-studies' ||
          this.routeLink == '/Blog' ||
          this.routeLink.includes('/case-single') ||
          this.routeLink.includes('/Blog-single') ||
          this.routeLink.includes('/photo-datails') ||
          this.routeLink == '/insight' ||
          this.routeLink == '/Books'
        ) {
          this.our_stories_active = true;
          this.service_active = false;
          this.company_active = false;
        }
      }
    });
    this.translate.addLangs(['en','ar'])
    this.translate.setDefaultLang(this.PePo.currentLang);
    this.translate.use(this.PePo.currentLang)
    this.PePo.setStyle();
    this.innerWidth = window.innerWidth;

    this.router.events.subscribe((event: any) => {
      // console.log(event);
      this.navigationInterceptor(event);
    });

    this.FooterSections()
  }

  setLanguage(lang: any): void {
    // Set the selected language for the toolbar
    this.selectedLanguage = lang;
    this.PePo.switchLanguage(lang, 'symbios_KSA');
  }

  // Shows and hides the loading spinner during RouterEvent changes
  navigationInterceptor(event: RouterEvent): void {
    if (event instanceof NavigationStart) {
      this.showOverlay = true;
    }
    if (event instanceof NavigationEnd) {
      this.showOverlay = false;
    }
  }


  Footer_Sectios: any;
  FooterSections() {
    this._apidata.getData(API_URLS.Footer.getWeb,this.PePo.AuthHeaders)?.subscribe((res) => {

      this.Footer_Sectios = res['body'][0]
    })
  }


}
