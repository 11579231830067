<!-- BREADCRUBS SECTION  -->
<div class="nw_background_banner"  style="background: url({{background}}) center bottom fixed;background-size: cover;">
  <div class="container">
    <div class="nw_layer_4">
      <h2 style="text-transform: uppercase;" [innerText]="PageName ? PageName : translate.instant(newString)"></h2>
    </div>
    <div class="nw_layer_5">
      <a href="#">{{translate.instant('Home')}}</a> / {{translate.instant(Main_Route)}} / {{PageName ? PageName : translate.instant(newString)}}
    </div>
  </div>
</div>
