import { PePoService } from 'src/@PePo/utils/PePo.service';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-gloabal-page',
  templateUrl: './gloabal-page.component.html',
  styleUrls: ['./gloabal-page.component.scss']
})
export class GloabalPageComponent implements OnInit {
  @Input('PageData') PageData: any;
  constructor(public _pepo: PePoService) { }

  ngOnInit(): void {
  }

}
