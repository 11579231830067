  <div class="expand-collapse">
    <div class="expand-collapse__head" (click)="expand_collapse($event)">
        <span class="expand-collapse__text" [innerText]="Header"></span>
        <div class="expand-collapse__arrow">
            <span class="icon">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" viewBox="0 0 20 20"
                    class="collapse-content__header-icon">
                    <path fill-rule="evenodd"
                        d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                        clip-rule="evenodd"></path>
                </svg>
            </span>
        </div>
    </div>

    <div class="expand-collapse__body">
      <ng-container *ngIf="body_img">
        <div>
          <img [src]="body_img" alt="img">
        </div>
      </ng-container>

      <ng-container *ngIf=" body_Txt">
        <div>
          <p [innerText]="body_Txt"></p>
        </div>
      </ng-container>

      <ng-container *ngIf=" body_html">
        <div class="list_collapse" [innerHTML]="body_html">
        </div>
      </ng-container>

      <ng-container *ngIf="data">
        <div class="list_collapse">
          <ul>
            <li *ngFor="let item of data; let i=index">
             <a  [routerLink]="['/training-workshops','training',item?._id]" routerLinkActive="router-link-active" >{{item.name[lang]}}</a>
            </li>
          </ul>
        </div>
      </ng-container>


    </div>
</div>
