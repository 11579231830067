// const uri = "https://ewj-server-nest.herokuapp.com/v1";
// const uri = 'http://ec2-3-235-2-16.compute-1.amazonaws.com:3000/v1';
const uri = 'https://symbios-ksa.co/v1';
const File_Uri = uri + '/File/';
export const API_URLS = {
  auth: {
    auth: uri + '/auth/login',
    changePassword: uri + '/Auth/changePassword',
    resetPassword: uri + '/Auth/resetPassword',
    register: uri + '/register',
  },
  File: {
    upload: uri + '/File/upload?project=symbios_ksa',
    UploadMultiple:'',
    delete: uri + '/File',
    download: uri + '/File/',
    get: uri + '/File',
    PATH: File_Uri
  },


  dummyObject: {
    post: uri + '/Openion',
    get: uri + '/Openion/all',
    getOne: uri + '/Openion/{entityId}',
    put: uri + '/Openion/{entityId}',
    delete: uri + '/Openion/{entityId}',
  },

  // Home Header
  HomeHeader: {
    getWeb: uri + "/homehead/web",
    getWebOne: uri + "/homehead/web/{entityId}"
  },
  Smvc: {
    getWeb: uri + "/smvc/web",
    getWebOne: uri + "/smvc/web/{entityId}"
  },
  Teams: {
    getWeb: uri + "/teams/web",
    getWebOne: uri + "/teams/web/{entityId}"
  },

  Partner: {
    getWeb: uri + "/partners/web",
    getWebOne: uri + "/partners/web/{entityId}"
  },
  Record: {
    getWeb: uri + "/record/web",
    getWebOne: uri + "/record/web/{entityId}"
  },
  Gallery: {
    getWeb: uri + "/gallery/web",
    getWebOne: uri + "/gallery/web/{entityId}"
  },
  Event: {
    getWeb: uri + "/event/web",
    getWebOne: uri + "/event/web/{entityId}"
  },

  Global_Company: {
    getWeb: uri + "/global-company/web",
    getWebOne: uri + "/global-company/web/{entityId}"
  },

  Services: {
    getWeb: uri + "/services/web",
    getWebOne: uri + "/services/web/{entityId}"
  },
  Report_Insights: {
    getWeb: uri + "/report-insights/web",
    getWebOne: uri + "/report-insights/web/{entityId}"
  },

  Contact_us: {
    post: uri + "/contact-us"
  },

  Footer: {
    getWeb: uri + "/footer/web",
    getWebOne: uri + "/footer/web/{entityId}"
  },

  Contact: {
    getWeb: uri + "/Contact/web",
  },

  Blogs: {
    getWeb: uri + "/blogs/web",
  },

  caseStudies: {
    getWeb: uri + "/case-studies/web",
  },

  engagement: {
    getWeb: uri + "/engagement/web",
  },
  TypeService: {
    getWeb: uri + "/services-type/web",
    getWebById: uri + "/services-type/web/{entityId}",
  },
  SigmaTraining: {
    getWeb: uri + "/sigma-training/web",
    getWebById: uri + "/sigma-training/web/{entityId}",
  },

};
